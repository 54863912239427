import React, { useState, useEffect } from 'react';
import Navbar from "../../components/navbar"
import { useParams } from 'react-router-dom';
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// function createData(number, item, qty, price) {
//   return { number, item, qty, price };
// }

const rows = [
  // createData(1, "Apple", 5, 3),
  // createData(2, "Orange", 2, 2),
  // createData(3, "Grapes", 3, 1),
  // createData(4, "Tomato", 2, 1.6),
  // createData(5, "Mango", 1.5, 4)
];

export default function Produitinfo(props) {
  const [count, setCount] = useState(0);
  // const data = props.location.state
  const { title ,prix  , information ,picture ,tab1 , tab2 , tab3 , tab4 , tab5 , tab6} = useParams();

  return (
    <>
    <Navbar/>
    <div className='produit-info-basic'>
      <img style={{height : "auto" , width : "30%" , marginTop : "5px"}} src={`/uploads/profil/${picture}`} alt="logo" />
      <h3 style={{color :   "#6C6C70" , fontFamily: "Century Gothic"}}>{title}</h3>
      <h3 style={{color :   "#6C6C70" , fontFamily: "Century Gothic"}}>{prix} dt</h3>
      <p style={{width: "80%" ,color :   "#6C6C70" , fontFamily: "Century Gothic" , textAlign: "center"}}>{information}</p>
    </div>
    </>
  );
}

//Produitinfo
