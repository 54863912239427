import React, { useState, useEffect } from "react";
import PoppupAjourer from "./poppupAjouter32";
import PoppupModule from "./poppupModification32";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function SousCategorie32() {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [information, setInformation] = useState("");
  const [prix, setPrix] = useState("");
  const [picture, setPicture] = useState("");
  const [poppupAjouter, setPoppupAjouter] = useState(false);
  const [poppupModifier, setPoppupModifier] = useState(false);


  const [appState, setAppState] = useState({
    loading: false,
    repos: null,
  });

  const handlerId = (id , title , information , prix , picture) => {
    setId(id);
    setTitle(title);
    setInformation(information);
    setPrix(prix);
    setPicture(picture);
    setPoppupModifier(true);
  };

  const handlerDelete = (id) => {
    setAppState({ loading: true });
    const apiUrl = `/api/admin/deletecategory32`;
    fetch(apiUrl, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    })
      .then((res) => {
        const apiUrl = `/api/admin/getcategory32`;
        fetch(apiUrl)
          .then((res) => res.json())
          .then((repos) => {
            setAppState({ loading: false, repos: repos });
          });
      })
      .then((repos) => {
        setAppState({ loading: false, repos: repos });
      });
  };



  useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `/api/admin/getcategory32`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((repos) => {
        setAppState({ loading: false, repos: repos });
      });
  }, [setAppState, poppupAjouter, poppupModifier]);

  return (
    <div className="alltable">
      <Button
        onClick={() => setPoppupAjouter(true)}
        sx={{ margin: "5px" }}
        variant="contained"
      >
        Ajouter
      </Button>
      <table id="categorie1">
        <thead>
          <tr>
            <th>Titre</th>
            <th>Information</th>
            <th>Prix</th>
            <th>Image</th>
            <th>Supprimer</th>
          </tr>
        </thead>
        <tbody>
          {appState.repos &&
            appState.repos.message &&
            appState.repos.message.map((val, index) => (
              <tr key={index}>
                <td onClick={() => handlerId(val.id ,
                                             val.title ,
                                             val.information ,
                                             val.prix ,
                                             val.picture)}>{val.title}</td>
                <td onClick={() => handlerId(val.id ,
                                             val.title ,
                                             val.information ,
                                             val.prix ,
                                             val.picture)}>{val.information}</td>
                <td onClick={() => handlerId(val.id ,
                                             val.title ,
                                             val.information ,
                                             val.prix ,
                                             val.picture)}>{val.prix}</td>
                <td onClick={() => handlerId(val.id ,
                                             val.title ,
                                             val.information ,
                                             val.prix ,
                                             val.picture)}>
                  <img
                    src={`/${val.picture}`}
                    alt="img"
                    width="auto"
                    height="40"
                  />
                </td>
                <td>
                  <Button
                    size="small"
                    variant="contained"
                    style={{
                      fontWeight: "bolder",
                      backgroundColor: "red",
                      margin: "5px",
                    }}
                    onClick={() => handlerDelete(val.id)}
                  >
                    supprimer
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
        {poppupAjouter && (
          <PoppupAjourer
            openPopup={(e) => setPoppupAjouter(e)}
            open={poppupAjouter}
          />
        )}
        {poppupModifier && (
          <PoppupModule
            openPopup={(e) => setPoppupModifier(e)}
            open={poppupModifier}
            id={id}
            _title={title}
            _information={information}
            _prix={prix}
            _picture={picture}
          />
        )}
      </table>
      {appState.loading && (
        <Box
          sx={{
            width: "100%",
            marginTop: "20vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
