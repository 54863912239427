import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'



export default function SimpleAccordion() {
  return (
    <>
    
      <Accordion style={{
        boxShadow : "none" ,
        display : "flex" , 
        flexDirection : "column" , 
        alignItems: "normal",
        // borderBottom : "2px solid #2367FA",
        // borderRight : "2px solid #2367FA",
        // borderRadius : "7px",
        backgroundColor: "#FEE59F",
        margin: 0

        }}>
        <AccordionSummary
          // expandIcon={}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          
          <Typography style={{
                color: "black",
                fontWeight: "600",
                fontSize: "18px",
                // borderTop: "2px solid transparent",
                transition: "all 220ms ease-in-out",
                width: "100%",
                display: "flex",
                justifyContent : "center",

          }}
          >
            <Link style={{ textDecoration : "none" , color: "rgb(83, 88, 145)"}} to="/categorie1"> Base parfums </Link>

          <ExpandMoreIcon 
          style={{
            color: "rgb(83, 88, 145)",
            fontWeight: "600",
            fontSize: "30px",
            borderTop: "2px solid transparent",
            transition: "all 220ms ease-in-out"
          }}
          />
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails style={{padding: "0"}}>

        
        <Typography style={{
                              cursor :"pointer" ,
                              fontWeight : "600" ,
                              color :"#EDEDED" ,
                              backgroundColor: "rgb(83, 88, 145)",
                              width:  "95.5vw",
                              height: "50px",
                              display : "flex",
                              alignItems : "center",
                              justifyContent : "center",
                              // borderBottom : "2px solid #2367FA"
                               }}>
               Parfums pour homme
          </Typography>
  
          
        </AccordionDetails>
        <AccordionDetails style={{padding: "0"}}>
        <Typography style={{
                              cursor :"pointer" ,
                              fontWeight : "600" ,
                              color :"#EDEDED" ,
                              backgroundColor: "rgb(83, 88, 145)",
                              width:  "95.5vw",
                              height: "50px",
                              display : "flex",
                              alignItems : "center",
                              justifyContent : "center",
                              // borderBottom : "2px solid #2367FA"
                               }}>
               Parfums pour femme
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{padding: "0"}}>
        <Typography style={{
                              cursor :"pointer" ,
                              fontWeight : "600" ,
                              color :"#EDEDED" ,
                              backgroundColor: "rgb(83, 88, 145)",
                              width:  "95.5vw",
                              height: "50px",
                              // borderRadius: "5px",
                              display : "flex",
                              alignItems : "center",
                              justifyContent : "center",
                               }}>
                Parfums UNISEX 
          </Typography>
        </AccordionDetails>
        <AccordionDetails style={{padding: "0"}}>
        <Typography style={{
                              cursor :"pointer" ,
                              fontWeight : "600" ,
                              color :"#EDEDED" ,
                              backgroundColor: "rgb(83, 88, 145)",
                              width:  "95.5vw",
                              height: "50px",
                              // borderRadius: "5px",
                              display : "flex",
                              alignItems : "center",
                              justifyContent : "center",
                               }}>
               Parfums detergent/airfresh
          </Typography>
        </AccordionDetails>
      </Accordion>
     
    </>
  );
}
