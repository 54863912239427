import React from "react";
import axios from "axios";
import cookie from "js-cookie";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Logout = () => {
  const removeCookie = (key) => {
    if (window !== "undefined") {
      cookie.remove(key, { expires: 1 });
    }
  };

  const logout = async () => {
    await axios({
      method: "get",
      url: `/api/admin/logout`,
      withCredentials: true,
    })
      .then(() => removeCookie("jwt"))
      .catch((err) => console.log(err));

    window.location = "/";
  };

  return (
      <ExitToAppIcon onClick={logout}/>
  );
};

export default Logout;
