import * as React from 'react';
import { Link } from 'react-router-dom'

export default function BasicMenu() {


  return (
    <div className='dropdown'> 
      <div className='ButtonStyle'>  My Variety </div>
      <div className="dropdown-content">
       <Link to="/categorie1"> My Variety </Link>
       <Link to="/categorie2"> Matières premières</Link>
       <Link to="/categorie3"> Huiles essentielles</Link>
      </div>
    </div>
  );
}
