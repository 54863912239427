import React, { useEffect, useState } from "react";
import axios from "axios";
import Body from "./components/body"
import Admin from "./pages/admin"

import Category1 from "./pages/category1"
import Category2 from "./pages/category2"
import Category3 from "./pages/category3"

import SousCategory11 from "./pages/sousCategory11"
import SousCategory12 from "./pages/sousCategory12"
import SousCategory13 from "./pages/sousCategory13"
import SousCategory14 from "./pages/sousCategory14"

import SousCategory21 from "./pages/sousCategory21"
import SousCategory22 from "./pages/sousCategory22"
import SousCategory23 from "./pages/sousCategory23"

import SousCategory31 from "./pages/sousCategory31"
import SousCategory32 from "./pages/sousCategory32"
import SousCategory33 from "./pages/sousCategory33"
import Produitinfo from "./pages/category1/produitinfo"
import Produitinfo1 from "./pages/category2/produitinfo"
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'

import Categorie1 from './pages/admin/routes/Categorie1';
import SousCategorie11 from './pages/admin/routes/SousCategorie11';
import SousCategorie12 from './pages/admin/routes/SousCategorie12';
import SousCategorie13 from './pages/admin/routes/SousCategorie13';
import SousCategorie14 from './pages/admin/routes/SousCategorie14';

import Categorie2 from './pages/admin/routes/Categorie2';
import SousCategorie21 from './pages/admin/routes/SousCategorie21';
import SousCategorie22 from './pages/admin/routes/SousCategorie22';
import SousCategorie23 from './pages/admin/routes/SousCategorie23';

import Categorie3 from './pages/admin/routes/Categorie3';
import SousCategorie31 from './pages/admin/routes/SousCategorie31';
import SousCategorie32 from './pages/admin/routes/SousCategorie32';
import SousCategorie33 from './pages/admin/routes/SousCategorie33';

import Sidebar from './components/Sidebar';

function App() {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchToken = async () => {
      await axios({
        method: "get",
        url: `/api/jwtid`,
        withCredentials: true,
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log("No tokenn"));
    };
    fetchToken();

  }, [data && data._id]);
  return (
    <>
    <Router>
      {data && data._id && <Sidebar />}
      <Routes>
           {data && data._id ? <></> : <Route path="/" element={ <Body/>} />}
          <Route path="/categorie1" element={<Category1/>}/>
          <Route path="/categorie2" element={<Category2/>}/>
          <Route path="/categorie3" element={<Category3/>}/>

          <Route path="/sousCategorie11" element={<SousCategory11/>}/>
          <Route path="/sousCategorie12" element={<SousCategory12/>}/>
          <Route path="/sousCategorie13" element={<SousCategory13/>}/>
          <Route path="/sousCategorie14" element={<SousCategory14/>}/>

          <Route path="/sousCategorie21" element={<SousCategory21/>}/>
          <Route path="/sousCategorie22" element={<SousCategory22/>}/>
          <Route path="/sousCategorie23" element={<SousCategory23/>}/>

          <Route path="/sousCategorie31" element={<SousCategory31/>}/>
          <Route path="/sousCategorie32" element={<SousCategory32/>}/>
          <Route path="/sousCategorie33" element={<SousCategory33/>}/>

          <Route path="/produitInfo/:title/:picture/:tab1/:tab2/:tab3/:tab4/:tab5/:tab6/:prix/:information" element={<Produitinfo/>}/>
          <Route path="/produitInfo/:title/:picture/:tab1/:tab2/:tab3/:tab4/:tab5/:tab6/:prix/:produit1/:produit2/:produit3" element={<Produitinfo1/>}/>
           {data && data._id ? <></> : <Route path="admin" element={ <Admin/>} /> }
           {data && data._id && <Route path='/admin/Categorie1' exact element={<Categorie1/>} /> }
           {data && data._id && <Route path='/admin/categorie1/SousCategorie11' exact element={<SousCategorie11/>} /> }
           {data && data._id && <Route path='/admin/categorie1/SousCategorie12' exact element={<SousCategorie12/>} /> }
           {data && data._id && <Route path='/admin/categorie1/SousCategorie13' exact element={<SousCategorie13/>} /> }
           {data && data._id && <Route path='/admin/categorie1/SousCategorie14' exact element={<SousCategorie14/>} /> }

           {data && data._id && <Route path='/admin/Categorie2' exact element={<Categorie2/>} /> }
           {data && data._id && <Route path='/admin/categorie2/SousCategorie21' exact element={<SousCategorie21/>} /> }
           {data && data._id && <Route path='/admin/categorie2/SousCategorie22' exact element={<SousCategorie22/>} /> }
           {data && data._id && <Route path='/admin/categorie2/SousCategorie23' exact element={<SousCategorie23/>} /> }

           {data && data._id && <Route path='/admin/Categorie3' exact element={<Categorie3/>} /> }
           {data && data._id && <Route path='/admin/categorie3/SousCategorie31' exact element={<SousCategorie31/>} /> }
           {data && data._id && <Route path='/admin/categorie3/SousCategorie32' exact element={<SousCategorie32/>} /> }
           {data && data._id && <Route path='/admin/categorie3/SousCategorie33' exact element={<SousCategorie33/>} /> }
      </Routes>
    </Router>

    </>
  );
}

export default App;
