import * as React from 'react';
import { Link } from 'react-router-dom'

export default function BasicMenu() {


  return (
    <div className='dropdown'> 
      <div className='ButtonStyle'>  Base parfums </div>
      <div className="dropdown-content">
       <Link to="/sousCategorie11">Parfums pour homme</Link>
       <Link to="/sousCategorie12">Parfums pour femme</Link>
       <Link to="/sousCategorie13">Parfums UNISEX</Link>
       <Link to="/sousCategorie14">Parfums detergent/airfresh</Link>
      </div>
    </div>
  );
}
