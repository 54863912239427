import React, { Component } from "react";
import { Link } from "react-router-dom";
import Produit from "./produit";
import Navbar from "../../components/navbar";

// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Fooder from "../../components/body/Fooder"

class SousCategory14 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      value: [0, 100],

      chekbox1: false,
      checkedType1: "",
      chekbox2: false,
      checkedType2: "",

      filterVal: "",
      filterValPrix: "",
      filterRestest: [],
      filterRes: [],
    };
  }

  componentDidMount() {
    fetch("/api/admin/getcategory14")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            filterRes: result && result.message,
            filterResInt: result && result.message,
            filterRes1: result,
            filterRes2: result,
            totalUser: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        }
      );
  }

  searchChange = (event) => {
    if (event.target.value) {
      let test = [];
      let filterResult = this.state.filterResInt.filter((val) =>
        val.title.includes([...test, event.target.value][0])
      );
      this.setState({
        filterRes: filterResult,
        filterVal: event.target.value,
      });
    } else {
      this.setState({
        filterRes: this.state.filterResInt,
        filterVal: event.target.value,
      });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    // this.setState({ chekbox1 : false  })
    // this.setState({ chekbox2 : false  })

    if (event.target.value) {
      let filterResult = this.state.filterResInt.filter(
        (res) =>
          res.prix <= this.state.value[1] && res.prix >= this.state.value[0]
      );
      this.setState({
        filterRes: filterResult,
        filterValPrix: event.target.value,
      });
    } else {
      this.setState({
        filterRes: this.state.filterResInt,
        filterValPrix: event.target.value,
      });
    }
  };

  // handleChangeType1 = (val) => {
  //   this.setState({ chekbox1 : !this.state.chekbox1  })
  //   this.setState({value : [0 , 100]})
  //   this.setState({ chekbox2 : false  })
  //     if(val) {
  //     let filterResult = this.state.filterRes1
  //       .filter((res) => res.type ===  "type1")
  //       .map((el) => {
  //         return el
  //       });
  //     this.setState({
  //       filterRes: filterResult,
  //       isLoaded: true,
  //     });
  //   }else {
  //     this.setState({
  //       filterRes: this.state.totalUser,
  //       isLoaded: true,
  //     });
  //   }
  // };

  // handleChangeType2 = (val) => {
  //   this.setState({ chekbox2 : !this.state.chekbox2  })
  //   this.setState({ chekbox1 : false  })
  //     if(val) {
  //     let filterResult = this.state.filterRes2
  //       .filter((res) => res.type ===  "type2")
  //       .map((el) => {
  //         return el
  //       });
  //     this.setState({
  //       filterRes: filterResult,
  //       isLoaded: true,
  //     });
  //   }else {
  //     this.setState({
  //       filterRes: this.state.totalUser,
  //       isLoaded: true,
  //     });
  //   }
  // };

  render() {
    const { filterRes, filterVal } = this.state;

    // const [value, setValue] = React.useState<number[]>([0, 137]);

    console.log("resuultat", this.state.filterVal);
    console.log("resuuuuultat", this.state.filterRes);

    return (
      <>
        <Navbar />
        <section className="flexFilter">
          <section className="Filter">
            <h4
              style={{ marginTop: "10px", width: "100%", textAlign: "center" }}
            >
              Filter Prix
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <Box sx={{ width: "80%" }}>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={this.state.value}
                  onChange={this.handleChange}
                  //  getAriaValueText={valuetext}
                  valueLabelDisplay="auto"
                />
              </Box>
            </div>
            {/* <div style={{marginLeft: "10px" , marginTop: "30px"}}>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={this.state.chekbox1} onChange={()=> this.handleChangeType1(!this.state.chekbox1)}/>} label="Type1" />
          <FormControlLabel control={<Checkbox checked={this.state.chekbox2} onChange={()=> this.handleChangeType2(!this.state.chekbox2)}/>} label="Type2" />
        </FormGroup>
        </div> */}
          </section>

          <section className="ListProduit">
            <section
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              <span className="vide"></span>
              <span
                style={{
                  fontFamily: "Century Gothic Bold",
                  fontSize: "1.4rem",
                  lineHeight: "1.2",
                  letterSpacing: "2px",
                  color: "#6C6C70",
                  textTransform: "uppercase",
                }}
              >
                Produit
              </span>

              <div>
                <TextField
                  autoComplete="off"
                  id="outlined-basic"
                  label="Recherche"
                  name="search"
                  value={filterVal}
                  onChange={this.searchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </div>
            </section>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {filterRes &&
                filterRes.map((val, index) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    key={index}
                    to={{
                      pathname: `/produitInfo/${val.title}/${val.picture.slice(
                        15
                      )}/tab1/tab2/tab3/tab4/tab5/tab6/${val.prix}/${val.information}`,
                    }}
                  >
                    {val.picture !== undefined ? (
                      <Produit
                        imgPro={val.picture}
                        title={val.title}
                        information={val.information}
                        prix={val.prix}
                      />
                    ) : null}
                  </Link>
                ))}
            </div>
          </section>
        </section>
        <Fooder/>
      </>
    );
  }
}

export default SousCategory14;
