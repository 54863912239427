import React, { useState ,useEffect  } from "react";
import styled from "styled-components";
import { MenuToggle } from "./menuToggle";
import SimpleAccordion1 from "./categorie1Responsive/index"
import SimpleAccordion2 from "./categorie2Responsive/index"
import SimpleAccordion3 from "./categorie3Responsive/index"
import HomeMobale from "./categorie1Responsive/homeMobale"
import Aboutus from "./categorie1Responsive/aboutusMobale"
import Contact from "./categorie1Responsive/contactMobile"


const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
  
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: auto;
  list-style: none;
  background-color:#FEE59F;
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0;
`;

const LinkItem = styled.li`
  width: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 16px;
  background-color: #FEE59F;
  margin-bottom: 10px;
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`;

const Marginer = styled.div`
  height: 2em;
`;

export function MobileNavLinks(props) {
  const [isOpen, setOpen] = useState(false);

  const [val, setVal] = React.useState(7);

  const handleChange = (event) => {
    setVal(event.target.value);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    // setOpen(position === 0 && isOpen )
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <>

    {!isOpen && <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />}


    <NavLinksContainer >
      {isOpen && (
        <LinksWrapper style={{zIndex : "99999999" }} >
        <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
        <LinkItem style={{padding: "2%" }}>
          {/* <Link href="#">About us</Link> */}
           <a style={{textDecoration :"none"}} href="#" className="tran1">
             <HomeMobale></HomeMobale>
           </a>
        </LinkItem>
        <LinkItem style={{padding: "2%"}}>
          {/* <Link href="#">About us</Link> */}
          <a style={{textDecoration :"none"}} href="#" className="tran2">
             <Aboutus></Aboutus>
          </a>
        </LinkItem>
          <LinkItem style={{padding: "2%"}}>
            {/* <Link href="#">About us</Link> */}
            <span className="tran3">
            <SimpleAccordion1></SimpleAccordion1>
            </span>
          </LinkItem>
          <LinkItem style={{padding: "2%"}}>
            {/* <Link href="#">How it works</Link> */}
            <span className="tran4">
            <SimpleAccordion2></SimpleAccordion2>
            </span>
          </LinkItem>
          <LinkItem style={{padding: "2%"}}>
          <span className="tran4">
            {/* <Link href="#">Explore</Link> */}
            <SimpleAccordion3></SimpleAccordion3>
            </span>
          </LinkItem>
          <LinkItem style={{padding: "2%"}}>
          <span className="tran4">
            {/* <Link href="#">Impact</Link> */}
            <Contact></Contact>
            </span>
          </LinkItem>
          <Marginer />
          {/* <Accessibility 
                          value={val}
                          handleChange={handleChange}
                          items={[
                            { key: "FR", value: 7 , flagCon:  <Flags.FR style={{width : "20px"}} type="button" title="United States" className=""/>},
                            { key: "EN", value: 28 , flagCon:  <Flags.GB style={{width : "20px"}} type="button" title="United States" className=""/>},
                          ]}
          /> */}
                  <LinkItem>
          {/* <Link href="#">About us</Link> */}
        </LinkItem>
        </LinksWrapper>
      )}
    </NavLinksContainer>
    </>
  );
}
