import React from 'react'
import SignIn from "./signIn"

export default function index() {
  return (
    <>
     <SignIn/>
    </>
   
  )
}
