import React, { useState } from "react";
// import { Language } from "./language";
// import { SempacoLogo } from "./sempacoLogo";
import { NavLinks } from "./navLinks";
import { MobileNavLinks } from "./mobileNavLinks";
import { useMediaQuery } from "react-responsive";
// import Flags from "country-flag-icons/react/3x2";

export default function Navbar() {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const [val, setVal] = useState(7);
  const handleChange = (event) => {
    setVal(event.target.value);
  };

  return (
    <section className="navbar">

      <span style={{ display: "flex", flexDirection: "column" , width: "100%" }}>
        <div className="mobileNav">   
          <span className="nav-reduit">{isMobile && <MobileNavLinks />}</span>
          {/* <SempacoLogo /> */}
        </div>

        <div className="navlist">{!isMobile && <NavLinks />}</div>
      </span>
    </section>
  );
}

export const DeviceSize = {
  mobile: 768,
  tablet: 992,
  laptop: 1324,
  desktop: 2024,
};
