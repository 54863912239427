import React from 'react'
import CarouselCom from "./CarouselCom"
import AboutUs from "./AboutUs"
import TreeCategorie from "./TreeCategorie"
import TopProduct from "./TopProduct"
import CallSupport from "./callSupport"
import Map from "./GoogleMap"
import Fooder from "./Fooder"
import Navbar from "../navbar"

export default function Body() {
  return (
    <>
    <Navbar/>
    <div className='body'>
        <CarouselCom />
        <AboutUs/>
        <TreeCategorie/>
        <TopProduct/>
        <CallSupport/>
        <Map/>
        <Fooder/>
    </div>
    </>
  )
}

