import React, { Component } from 'react';



class Produit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        };
      }
    render() {
        const {imgPro , title , prix } = this.props
        return (
            <div style={{display: "flex" , flexDirection: "column" , justifyContent: "center" , alignItems: "center"}}>
                <img className='produit-image'  src={`/${imgPro}`} alt="logo" />
                <p style={{ fontFamily: 'Century Gothic',
                            color: '#6C6C70' ,
                            fontSize: "x-large", 
                            fontWeight: "bolder",
                            textDecoration: "none"}}>{title}</p>
               <p style={{ fontFamily: 'Century Gothic',
                            color: '#6C6C70' ,
                            fontSize: "x-large", 
                            fontWeight: "bolder",
                            textDecoration: "none"}}>{prix} dt</p>                       
            </div>
        );
    }
}

export default Produit;
