import React, { Component } from 'react'

export default class TopProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded:false,
      data: {},
      isLoaded1:false,
      data1: {},
      isLoaded2:false,
      data2: {}
    };
  }

  componentDidMount() {
    fetch("/api/admin/getcategory1")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      )
      .then(
        fetch("/api/admin/getcategory2")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded1: true,
              data1: result
            });
          },
          (error) => {
            this.setState({
              isLoaded1: false,
              error
            });
          }
        )
      )
      .then(
        fetch("/api/admin/getcategory3")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded2: true,
              data2: result
            });
          },
          (error) => {
            this.setState({
              isLoaded2: false,
              error
            });
          }
        )
      )
  }

  render() {
    const { isLoaded, data , isLoaded1, data1 , isLoaded2, data2} = this.state;
    return (
      <section>

      <div style={{marginTop: "50px" ,display : "flex", alignItems: "center", justifyContent:"center", flexDirection: "column", width : "100%" , backgroundColor: "#FEE59F" ,paddingBottom: "0.94118rem"}}>
          <h2 style={{marginTop: "30px" , color : "rgb(83, 88, 145)" }}>Recommender</h2>
      </div>
     
      <section style={{ width : "80%" ,
                        margin : "auto" , 
                        marginTop : "20px",
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap"
                        }}>

         {
           data.message &&
           data.message.filter((el) => el.checked) 
                       .map((val, index) => (
            <div key={index}>
            <div style={{width : "300px" , margin: "10px 20px 0 20px"}}>
              <img style={{height : "auto" , width : "300px"}} src={val.picture} alt="logo" />
              <p style={{ width: "100%", display: "flex" , justifyContent: "center" , fontSize: "16px" , marginTop : "10px"}}>{val.title}</p>
              <p style={{width: "100%", display: "flex" , justifyContent: "center"  , fontSize: "16px" , marginTop : "10px"}}>{val.prix} dt</p>
            </div>
            </div>
          ))}         

                   {
           data1.message &&
           data1.message.filter((el) => el.checked) 
                       .map((val, index) => (
            <div key={index}>
            <div style={{width : "300px"  , margin: "10px 20px 0 20px"}}>
              <img style={{height : "auto" , width : "300px"}} src={val.picture} alt="logo" />
              <p style={{ width: "100%", display: "flex" , justifyContent: "center"  , fontSize: "16px" , marginTop : "10px"}}>{val.information}</p>
              <p style={{width: "100%", display: "flex" , justifyContent: "center"  , fontSize: "16px" , marginTop : "10px"}}>{val.prix} </p>
            </div>
            </div>
          ))}          
         {
           data2.message &&
           data2.message.filter((el) => el.checked) 
                       .map((val, index) => (
            <div key={index}>
            <div style={{width : "300px"  , margin: "10px 20px 0 20px"}}>
              <img style={{height : "auto" , width : "300px"}} src={val.picture} alt="logo" />
              <p style={{width: "100%", display: "flex" , justifyContent: "center" , fontSize: "16px" , marginTop : "10px"}}>{val.information}</p>
              <p style={{width: "100%", display: "flex" , justifyContent: "center"  , fontSize: "16px" , marginTop : "10px"}}>{val.prix} </p>
            </div>
            </div>
          ))} 
      </section>





      </section>
    )
  }
}
