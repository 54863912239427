import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";

export default function Categorie1() {

  const [appState, setAppState] = useState({
    loading: false,
    repos: null,
  });

  const handleChangeCheckbox = async (id , checked) => {
    if(id !== "") {
      await axios.put('/api/admin/updatecategory1', {
        id: id,
        checked: !checked
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
    }
  };


  useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `/api/admin/getcategory1`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((repos) => {
        setAppState({ loading: false, repos: repos });
      });
  }, [setAppState]);

  return (
    <div className="alltable">
      <table id="categorie1">
        <thead>
          <tr>
            <th>Titre</th>
            <th>Information</th>
            <th>Prix</th>
            <th>Image</th>
            <th>Favoris</th>
          </tr>
        </thead>
        <tbody>
          {appState.repos &&
            appState.repos.message &&
            appState.repos.message.map((val, index) => (
              <tr key={index}>
                <td>{val.title}</td>
                <td>{val.information}</td>
                <td>{val.prix}</td>
                <td>
                  <img
                    src={`/${val.picture}`}
                    alt="img"
                    width="auto"
                    height="40"
                  />
                </td>
                <td>
                  <Checkbox
                    defaultChecked={val.checked}
                    onChange={() => handleChangeCheckbox(val.id , val.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {appState.loading && (
        <Box
          sx={{
            width: "100%",
            marginTop: "20vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
