import React, { Component } from "react";

class Map extends Component {
  render() {
    return (
      <div style={{width : "100%"}}>
              <iframe
                src="https://maps.google.com/maps?q=sfax%20SEMPACO&t=&z=14&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="yes"
                marginHeight="0"
                marginWidth="0"
                width="100%"
                height="400px"
              ></iframe>
      </div>
    );
  }
}

export default Map;


