import React, { Component } from "react";

export default class AboutUs extends Component {
  render() {
    return (
      <div
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        className="about-us"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <div
            id="ABOUTUS"
            style={{
              width: "400px",
              paddingTop: "50px",
            }}
          >
            <h3 className="TitreQuiSomme">Qui somme nous</h3>
            <p className="quiSomme" >
              Depuis plus de 25 ans Sempaco s’est spécialisée dans le secteur cosmétique et parfumerie.
              En effet , elle met à disposition de se clients une expérience et un héritage
              d’un savoir-faire de toute la famille
              Meziou qui a gagné sa renommée ce domaine depuis plusieurs
              générations
              La proximité et la générosité étaient et demeurent la
              devise de notre société.
            </p>
            <p className="elleOffre">
              Elle offre ainsi une multitude de services tels que l’assistance,
              le conseil, l’accompagnement et la gestion des projets.
            </p>


          </div>
          <div
            style={{
              width: "500px",
              paddingTop: "50px",
            }}
          >
            <div className="video-responsive">
              <iframe
                src={`https://www.youtube.com/embed/wDhysutq8-w?autoplay=1&mute=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>

            <p
              style={{
                color: "rgb(93, 93, 105)",
                marginTop: "0.58824rem",
                fontWeight: "400",
                letterSpacing: "normal",
                fontSize: "1.11176rem",
                lineHeight: "1.94118rem",
              }}
            >

            </p>

            <p className="imposee">
              Sempaco s’est imposée dans le marché tunisien et libyen grâce à la
              qualité de ses produits phares et de ses services et compte bien
              s’élargir et s’ouvrir à d’autres marchés.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
