import React, { useState ,useEffect  } from "react";
import { Language } from "./language";
import styled from "styled-components";
import BasicMenu1 from "./categorie1/index";
import BasicMenu2 from "./categorie2/index";
import BasicMenu3 from "./categorie3/index";
import Flags from "country-flag-icons/react/3x2";
import { SempacoLogo } from "./sempacoLogo";
import { Link } from 'react-router-dom'

const NavLinksContainer = styled.div`
  height: 100%;
  width : 100%;
  /* display: flex;
  align-items: center; */
  margin: auto;
  background-color : #FEE59F;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
`;

const LinkItem = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #2367FA;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-top: 2px solid transparent;
  transition: all 220ms ease-in-out;

  /* &:hover {
    border-top: 2px solid #133C99;
  } */
`;




export function NavLinks(props) {

  const [val, setVal] = useState(7);
  const handleChange = (event) => {
    setVal(event.target.value);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  return (
    <div  style={{width: "100%" ,backgroundColor : "#fff" ,position  : "fixed" , zIndex : "999999999" ,  top: "0px"}}>
          {/* <div  style={ scrollPosition > 0 ? {width: "100%" ,backgroundColor : "#fff" ,position  : "fixed" , zIndex : "999999999" ,  top: "0px"} :  {width: "100%"  ,position  : "initial"}  }></div> */}
    <NavLinksContainer >

      <LinksWrapper style={{  display: "flex", justifyContent: "space-between" ,alignItems: "center"}}>
        <LinkItem>
           <SempacoLogo/>
        </LinkItem>
      
      <span style={{display: "flex" , justifyContent: "space-between" , alignItems: "center"}}>
        <LinkItem>
          <Link style={{textDecoration : "none"}} to="/">
           <section href="#" className="ButtonStyle">
             HOME
           </section>
           </Link>
        </LinkItem>
        <LinkItem>
           <a style={{textDecoration :"none"}} href="#ABOUTUS" className="ButtonStyle">
             ABOUT US
           </a>
        </LinkItem>
        <LinkItem>
          <Link to="/categorie1">
           <BasicMenu1></BasicMenu1>
          </Link>
        </LinkItem>
        <LinkItem>
          <Link to="/categorie2">
            <BasicMenu2></BasicMenu2>
          </Link>
        </LinkItem>
        <LinkItem>
          <Link to="/categorie3">
            <BasicMenu3></BasicMenu3>
          </Link>
        </LinkItem>

        <LinkItem>
          {/* <Link href="#">About us</Link> */}
           <a style={{textDecoration :"none"}} href="#CONTACT" className="ButtonStyle">
             CONTACT
           </a>
        </LinkItem>
        </span>
      

      <div className="box1" style={{marginRight: "10px"}}>
          <Language
            value={val}
            handleChange={handleChange}
            items={[
              {
                key: "FR",
                value: 7,
                flagCon: (
                  <Flags.FR 
                    style={{ width: "20px"}}
                    type="button"
                    title="United States"
                  />
                ),
              },
              {
                key: "EN",
                value: 28,
                flagCon: (
                  <Flags.GB
                    style={{ width: "20px" }}
                    type="button"
                    title="United States"
                  />
                ),
              },
            ]}
          />
        </div>
        </LinksWrapper>


    </NavLinksContainer>
    </div>
  );
}
