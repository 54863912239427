import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

export default function PoppupAjourer({ openPopup, open , id , _title , _tab1 , _tab2 , _tab3 , _tab4 , _tab5 , _tab6 , _prix , _picture , _produit1 , _produit2 , _produit3}) {
  const [produit1, setProduit1] = useState(_produit1);
  const [produit2, setProduit2] = useState(_produit2);
  const [produit3, setProduit3] = useState(_produit3);
  const [appState, setAppState] = useState({
    loading: false,
    repos: null,
  });
  const [title, setTitle] = useState(_title);
  const [prix, setPrix] = useState(_prix);
  const [tab1, setTab1] = useState(_tab1);
  const [tab2, setTab2] = useState(_tab2);
  const [tab3, setTab3] = useState(_tab3);
  const [tab4, setTab4] = useState(_tab4);
  const [tab5, setTab5] = useState(_tab5);
  const [tab6, setTab6] = useState(_tab6);
  const [file, setFile] = useState(_picture);
  const [imgEmty, setImgEmty] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);

  const handleChangeProduit1 = (event) => {
    setProduit1(event.target.value);
  };

  const handleChangeProduit2 = (event) => {
    setProduit2(event.target.value);
  };

  const handleChangeProduit3 = (event) => {
    setProduit3(event.target.value);
  };

  useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `/api/admin/getcategory2`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((repos) => {
        setAppState({ loading: false, repos: repos });
      });
  }, [setAppState]);

  const handleCategory = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", id);
    data.append("title", title);
    data.append("prix", prix);
    data.append("tab1", tab1);
    data.append("tab2", tab2);
    data.append("tab3", tab3);
    data.append("tab4", tab4);
    data.append("tab5", tab5);
    data.append("tab6", tab6);
    data.append("produit1", produit1);
    data.append("produit2", produit2);
    data.append("produit3", produit3);
    data.append("file", file);
    if (file !== undefined) {
      setLoadingImg(!loadingImg);
      await axios
        .request({
          url: "/api/admin/updatecategory22",
          method: "PUT",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: data,
        })
        .then((res) => openPopup(false))
        .catch((err) => console.log(err))
    } else {
      setImgEmty(!imgEmty);
    }
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="poppup">
        <div className="poppup-title">
          <h4>Ajouter</h4>
          <div className="poppup-close" onClick={() => openPopup(false)}>
            x
          </div>
        </div>
        <div className="poppup-body">
          <div className="poppup-body-box">
            <div className="poppup-body-box1">
              <div>
                <h5>Title :</h5>
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  name="title"
                  value={title}
                  className="input-box"
                  placeholder="title"
                />
              </div>
              <div>
                <h5>Nominal Capacity :</h5>
                <input
                  type="text"
                  onChange={(e) => setTab1(e.target.value)}
                  name="tab1"
                  value={tab1}
                  className="input-box"
                  placeholder="Nominal Capacity"
                />
              </div>
              <div>
                <h5>Diameter :</h5>
                <input
                  type="text"
                  onChange={(e) => setTab2(e.target.value)}
                  name="tab2"
                  value={tab2}
                  className="input-box"
                  placeholder="Diameter"
                />
              </div>
              <div>
                <h5>Height :</h5>
                <input
                  type="text"
                  name="tab3"
                  value={tab3}
                  className="input-box"
                  placeholder="Height"
                  onChange={(e) => setTab3(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="poppup-body-box2">
              <div>
                <h5>Prix :</h5>
                <input
                  type="text"
                  onChange={(e) => setPrix(e.target.value)}
                  name="prix"
                  value={prix}
                  className="input-box"
                  placeholder="Prix"
                />
              </div>
              <div>
                <h5>Neck Finish :</h5>
                <input
                  type="text"
                  onChange={(e) => setTab4(e.target.value)}
                  name="tab4"
                  value={tab4}
                  className="input-box"
                  placeholder="Neck Finish"
                />
              </div>
              <div>
                <h5>Pallet Quantity :</h5>
                <input
                  type="text"
                  onChange={(e) => setTab5(e.target.value)}
                  name="tab5"
                  value={tab5}
                  className="input-box"
                  placeholder="Pallet Quantity"
                />
              </div>
              <div>
                <h5>Style :</h5>
                <input
                  type="text"
                  name="tab6"
                  value={tab6}
                  className="input-box"
                  placeholder="style"
                  onChange={(e) => setTab6(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <h4>Image :</h4>
          <div className="button-box">
            <span>
              <input
                type="file"
                id="file"
                name="file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => setFile(e.target.files[0])}
              />
              {!imgEmty && <h5>Attacher produit :</h5>}
              <FormControl sx={{ margin: "2px" , minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">Attacher 1</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={produit1}
                    label="Attacher"
                    onChange={handleChangeProduit1}
                  >
                    {appState.repos &&
                      appState.repos.message &&
                      appState.repos.message.map((val1, index1) => (
                          <MenuItem key={index1} value={val1.id}>{val1.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ margin: "2px", minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">Attacher 2</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={produit2}
                    label="Attacher"
                    onChange={handleChangeProduit2}
                  >
                    {appState.repos &&
                      appState.repos.message &&
                      appState.repos.message.map((val1, index1) => (
                          <MenuItem key={index1} value={val1.id}>{val1.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                
                <FormControl sx={{ margin: "2px", minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">Attacher 3</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={produit3}
                    label="Attacher"
                    onChange={handleChangeProduit3}
                  >
                    {appState.repos &&
                      appState.repos.message &&
                      appState.repos.message.map((val1, index1) => (
                          <MenuItem key={index1} value={val1.id}>{val1.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
            </span>
            <Button
              onClick={handleCategory}
              style={{
                width: "200px",
                backgroundColor: "#306ED9",
                color: "#fff",
                marginTop: "50px"
              }}
              sx={{ margin: "0 5px -10px 0" }}
            >
              Enregister
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
