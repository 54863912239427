import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import axios from "axios";

export default function PoppupAjourer({ openPopup, open , id , _title , _information , _prix , _picture}) {
  const [title, setTitle] = useState(_title);
  const [prix, setPrix] = useState(_prix);
  const [information, setInformation] = useState(_information);
  const [file, setFile] = useState(_picture);
  const [imgEmty, setImgEmty] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);

  console.log("pppp" , _picture)

  const handleCategory = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", id);
    data.append("title", title);
    data.append("prix", prix);
    data.append("information", information);
    data.append("file", file);
    if (file !== undefined) {
      setLoadingImg(!loadingImg);
      console.log("ppppfff" , file)
      await axios
        .request({
          url: "/api/admin/updatecategory12",
          method: "PUT",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: data,
        })
        .then((res) => openPopup(false))
        .catch((err) => console.log(err))
    } else {
      setImgEmty(!imgEmty);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="poppup">
        <div className="poppup-title">
          <h4>Modifier</h4>
          <div className="poppup-close" onClick={() => openPopup(false)}>
            x
          </div>
        </div>
        <div className="poppup-body">
          <h3>title :</h3>
          <input
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            name="title"
            value={title}
            className="input-box"
            placeholder="titre"
          />
          <h3>Prix :</h3>
          <input
            type="text"
            onChange={(e) => setPrix(e.target.value)}
            name="prix"
            value={prix}
            className="input-box"
            placeholder="prix"
          />
          <h3>Inforation :</h3>
          <textarea 
            type="text"
            name="information"
            value={information}
            className="textarea-box"
            placeholder="information"
            onChange={(e) => setInformation(e.target.value)}
            >
          </textarea>
          <h3>Image :</h3>
          <div className="button-box">
         <span>
          <input
          type="file"
          id="file"
          name="file"
          accept=".jpg, .jpeg, .png"
          onChange={(e) => setFile(e.target.files[0])}
        />
        {!imgEmty && <h5>Saisir une image</h5>}
        </span>
        <Button
          onClick={handleCategory}
          style={{ width: "200px", backgroundColor: "#306ED9" , color : "#fff"}}
          sx={{ margin: "0 5px -10px 0" }}
          // variant="contained"
        >
          Enregister
        </Button>
        </div>
        </div>

      </div>
    </Modal>
  );
}
