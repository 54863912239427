import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



export default function Aboutus() {
  return (
    <>
      <Accordion style={{
        boxShadow : "none" ,
        display : "flex" , 
        flexDirection : "column" , 
        alignItems: "normal",
        // borderBottom : "2px solid #2367FA",
        // borderRight : "2px solid #2367FA",
        // borderRadius : "7px",
        backgroundColor : "#FEE59F",
        margin: 0

        }}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{
                            width: "100%",
                            display: "flex",
                            justifyContent : "center",
          }}>
           <a style={{
                color: "black",
                fontWeight: "600",
                fontSize: "18px",
                // borderTop: "2px solid transparent",
                transition: "all 220ms ease-in-out",
                textDecoration :"none",
                color : "rgb(83, 88, 145)"
          }} href="#ABOUTUS">
             ABOUT US
           </a>

          </Typography>
        </AccordionSummary>
   
      </Accordion>
     
    </>
  );
}
