import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar"
import { useParams } from 'react-router-dom';
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Fooder from "../../components/body/Fooder"




export default function Produitinfo(props) {

  const [appState, setAppState] = useState({
    loading: false,
    repos: null,
  });
  
  useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `/api/admin/getcategory2`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((repos) => {
        setAppState({ loading: false, repos: repos });
      });
  }, [setAppState]);
                                    

  const { title ,picture, prix ,tab1 , tab2 , tab3 , tab4 , tab5 , tab6 , produit1 , produit2 , produit3 } = useParams();



  const Produit1Filter = () => {
    if (produit1 !== "init") {
      return appState &&
      appState.repos && 
      appState.repos.message
      .filter((el) => el.id === produit1)
      return "init"
    }
  }

  const Produit2Filter = () => {
    if (produit2 !== "init") {
      return appState &&
      appState.repos && 
      appState.repos.message
      .filter((el) => el.id === produit2)
    } else {
      return "init"
    }
  }

  const Produit3Filter = () => {
    if (produit3 !== "init") {
      return appState &&
      appState.repos && 
      appState.repos.message
      .filter((el) => el.id === produit3)
    } else {
      return "init"
    }
  }


  return (
  
    <>
    <Navbar/>
    <div className='produit-info'>
      <div className='produit-info-big-box'>
      <div className='produit-info-img-flex'>
        <img className='produit-info-img1'  src={`/uploads/profil/${picture}`} alt="logo" />
      </div>
      <h3 style={{color :   "#6C6C70" , fontFamily: "Century Gothic"}}>{title}</h3>
      <h3 style={{color :   "#6C6C70" , fontFamily: "Century Gothic"}}>{prix} dt</h3>
      <div className='produit-info-tableau'>
      <TableContainer  component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow >
            <TableCell style={{ color : "#6C6C70"}}  align="center">Nominal Capacity </TableCell>
            <TableCell style={{ color : "#6C6C70"}}  align="center">Diameter</TableCell>
            <TableCell style={{ color : "#6C6C70"}}  align="center">Height</TableCell>
            <TableCell style={{ color : "#6C6C70"}}  align="center">Neck Finish</TableCell>
            <TableCell style={{ color : "#6C6C70"}}  align="center">Pallet Quantity</TableCell>
            <TableCell style={{ color : "#6C6C70"}}  align="center">Style</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

            <TableRow>
              <TableCell style={{ color : "#6C6C70"}}  component="th" scope="row">
                {tab1}
              </TableCell>
              <TableCell style={{ color : "#6C6C70"}}  align="center">{tab2}</TableCell>
              <TableCell style={{ color : "#6C6C70"}}  align="center">{tab3}</TableCell>
              <TableCell style={{ color : "#6C6C70"}}  align="center">{tab4}</TableCell>
              <TableCell style={{ color : "#6C6C70"}}  align="center">{tab5}</TableCell>
              <TableCell style={{ color : "#6C6C70"}}  align="center">{tab6}</TableCell>
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </div>
       {console.log("produit1" , Produit1Filter())}
      {!(produit1 === "init" && produit2 === "init" && produit3 === "init") && 
        <div className='produit-info-img2'>
          {produit1 !== "init" &&
                            <Link
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname: `/produitInfo/${Produit1Filter() && Produit1Filter()[0].title}/${Produit1Filter() && Produit1Filter()[0].picture.slice(
                                15
                              )}/${Produit1Filter() && Produit1Filter()[0].tab1}/${Produit1Filter() && Produit1Filter()[0].tab2}/${Produit1Filter() && Produit1Filter()[0].tab3}/${Produit1Filter() && Produit1Filter()[0].tab4}/${
                                Produit1Filter() &&  Produit1Filter()[0].tab5
                              }/${Produit1Filter() && Produit1Filter()[0].tab6}/${Produit1Filter() && Produit1Filter()[0].prix}/${Produit1Filter() && Produit1Filter()[0].produit1}/${Produit1Filter() && Produit1Filter()[0].produit2}/${Produit1Filter() && Produit1Filter()[0].produit3}`,
                            }}
                            >
                                      <span className='produit-info-box-box'>
                                        <h5 style={{textAlign : "center"}}>{Produit1Filter() && Produit1Filter()[0].title}</h5>
                                        <img className='produit-info-img21'  src={`/${Produit1Filter() && Produit1Filter()[0].picture}`} alt="logo" /> 
                                      </span>
                           </Link>

          }
         
          {produit2 !== "init" && 
                            <Link
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname: `/produitInfo/${Produit2Filter() && Produit2Filter()[0].title}/${Produit2Filter() && Produit2Filter()[0].picture.slice(
                                15
                              )}/${Produit2Filter() && Produit2Filter()[0].tab1}/${Produit2Filter() && Produit2Filter()[0].tab2}/${Produit2Filter() && Produit2Filter()[0].tab3}/${Produit2Filter() && Produit2Filter()[0].tab4}/${
                                Produit2Filter() &&  Produit2Filter()[0].tab5
                              }/${Produit2Filter() && Produit2Filter()[0].tab6}/${Produit2Filter() && Produit2Filter()[0].prix}/${Produit2Filter() && Produit2Filter()[0].produit1}/${Produit2Filter() && Produit2Filter()[0].produit2}/${Produit2Filter() && Produit2Filter()[0].produit3}`,
                            }}
                            >
                                      <span className='produit-info-box-box'>
                                        <h5 style={{textAlign : "center"}}>{Produit2Filter() && Produit2Filter()[0].title}</h5>
                                        <img className='produit-info-img21'  src={`/${Produit2Filter() && Produit2Filter()[0].picture}`} alt="logo" /> 
                                      </span>
                           </Link>
          }
          {produit3 !== "init" &&  
                            <Link
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname: `/produitInfo/${Produit3Filter() && Produit3Filter()[0].title}/${Produit3Filter() && Produit3Filter()[0].picture.slice(
                                15
                              )}/${Produit3Filter() && Produit3Filter()[0].tab1}/${Produit3Filter() && Produit3Filter()[0].tab2}/${Produit3Filter() && Produit3Filter()[0].tab3}/${Produit3Filter() && Produit3Filter()[0].tab4}/${
                                Produit3Filter() &&  Produit3Filter()[0].tab5
                              }/${Produit3Filter() && Produit3Filter()[0].tab6}/${Produit3Filter() && Produit3Filter()[0].prix}/${Produit3Filter() && Produit3Filter()[0].produit1}/${Produit3Filter() && Produit3Filter()[0].produit2}/${Produit3Filter() && Produit3Filter()[0].produit3}`,
                            }}
                            >
                                      <span className='produit-info-box-box'>
                                        <h5 style={{textAlign : "center"}}>{Produit3Filter() && Produit3Filter()[0].title}</h5>
                                        <img className='produit-info-img21'  src={`/${Produit3Filter() && Produit3Filter()[0].picture}`} alt="logo" /> 
                                      </span>
                           </Link>
          }
        </div>
         }

    </div>
    <Fooder/>
    </>
  );
}


