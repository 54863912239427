import * as React from 'react';
import { Link } from 'react-router-dom'

export default function BasicMenu() {


  return (
    <div className='dropdown'> 
      <div className='ButtonStyle'>  Emballage et accessoires </div>
      <div className="dropdown-content">
      <Link to="/sousCategorie21">Emballage en verre </Link>
      <Link to="/sousCategorie22">Emballahe en plastique </Link>
      <Link to="/sousCategorie23">Accessoires </Link>
      </div>
    </div>
  );
}
