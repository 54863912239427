import React, { Component } from 'react'
import FacebookIcon from "@mui/icons-material/Facebook";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from "@mui/icons-material/Email";
import image6 from "../../styles/assets/eryh.png";
import logo from "../../styles/assets/logo.PNG";
import {FloatingWhatsApp} from 'react-floating-whatsapp'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export default class Fooder extends Component {
  render() {
    return (
      <section
      style={{
        backgroundColor: "#FEE59F",
        width: "100%",
        minHeight: "400px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap"
        }}
        className='respensive-fooder'
      >

        <section className='imageFooder'>
          <h3  style={{ color: "#535891" }}>SEMPACO</h3>
          <img src={image6} style={{width : "80px" , height : "auto"}}/>
        </section>

        <div style={{ height: "20px" , height: "100px" , marginTop : "50px"}}>
          <h3 className='contactezNous'> Contactez-nous : </h3>
          <ul style={{ textAlign: "start", listStyleType: "none" }}>
            <li style={{  paddingLeft: "50px" }}>
            <a style={{ color: "#535891" , textDecoration : "none" }} href="tel:+21674443620">
                <AddIcCallIcon /> +216 70 295 742  	&nbsp;
                                  +216 74 223 850   &nbsp;
            </a>
            </li>
            <li style={{ color: "#535891" , paddingLeft: "50px" }}>
            <a style={{ color: "#535891"  , textDecoration : "none" }} href="tel:+21653416577">
                <AddIcCallIcon /> +216 27 050 044   	&nbsp;
                                  +216 27 050 041   	&nbsp;
                                  +216 27 050 066 	  &nbsp;
            </a>
            </li>
            <li style={{ color: "#535891" , paddingLeft: "50px" }}>

              <a style={{ color: "#535891"  , textDecoration : "none" }} href="mailto:med.sleh.meziou@gmail.com">
                <EmailIcon /> med.sleh.meziou@gmail.com 
              </a>

            </li>
          </ul>
        </div>

        <div style={{ height: "20px" , height: "100px" , marginTop : "50px"}}>
          <h3 style={{ color: "#535891" }}>
            Réseaux sociaux :
          </h3>
          <ul style={{ textAlign: "start", listStyleType: "none" }}>
            <li style={{ color: "#fff"}}>
              <a style={{ color: "#535891" , textDecoration : "none" }} target="_blank" href="https://www.facebook.com/people/Sempaco/100063749658493/?mibextid=opq0tG">
                <FacebookIcon />  facebook
              </a>
            </li>
            <li style={{ color: "#535891" }} >
              <a style={{ color: "#535891" , textDecoration : "none"}} target="_blank" href="https://www.instagram.com/sempaco_meziou/">
                <InstagramIcon />{" "} Instagram
              </a>
            </li>
          </ul>
          <h3 style={{ color: "#535891" , textAlign : "start" }}>
            RIB Banque: 
          </h3>
          <ul style={{ textAlign: "start", listStyleType: "none" }}>
            <li style={{ color: "#535891" }}>
                <AccountBalanceIcon />
                <a style={{ color: "#535891" }}  href="#">{" "}
                  UIB 12701 0001800704029 58 <br/>
                  &nbsp; &nbsp; &nbsp; &nbsp;  UBCI 11700 0001761002788 40
                 </a>
            </li>
          </ul>
        </div>



        <FloatingWhatsApp 
                          phoneNumber="+21629657198" 
                          accountName="SEMPACO"
                          avatar={logo} 
                          notification={true}
                          chatMessage="bienvenue au SEMPACO, Nos conseillers sont a votre disposition du lundi au samedi"
        >
        </FloatingWhatsApp>


      </div>

      <span className='copieRi'>
      <h8 className="toutdoitres" id="CONTACT">SITE WEB RÉALISÉ PAR 
          <span style={{ color: "#000" }}>  SEMPACO</span>
      | © 2024. TOUS DROITS RÉSERVÉS
      </h8>
      </span>
      
    </section>
    )
  }
}
