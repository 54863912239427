import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";
import axios from "axios";

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // const emailError = document.querySelector(".email.error");
    // const passwordError = document.querySelector(".password.error");
    // sleh.meziou29@gmail.com
    // u3Y59CwHVsk5

    axios({
      method: "post",
      url: `/api/admin/login`,
      withCredentials: true,
      data: {
        email,
        password,
      },
    })
      .then(() => {
        window.location = "/admin";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#D7D7D7",
          borderRadius: "10px",
          minWidth: "300px",
          width: "500px",
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "20px",
          marginRight: "20px"
        }}
      >
        <h2>Partie Admin</h2>
        <TextField
          label="Enter Votre Email"
          id="outlined-start-adornment"
          sx={{
            m: 1,
            width: "30ch",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          value={email}
          onChange={handleChangeEmail}
        />

        <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handleChangePassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            sx={{ backgroundColor: "#fff", borderRadius: "5px" }}
            label="Password"
          />
          <Button
            sx={{ marginTop: "20px" }}
            variant="contained"
            onClick={handleLogin}
          >
            Enter
          </Button>
        </FormControl>
      </Box>
    </div>
  );
}
