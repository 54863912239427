import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'Catégorie 1',
    path: '/admin/Categorie1',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Sous Catégorie 11',
        path: '/admin/categorie1/souscategorie11',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Sous Catégorie 12',
        path: '/admin/categorie1/souscategorie12',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Sous Catégorie 13',
        path: '/admin/categorie1/souscategorie13',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Sous Catégorie 14',
        path: '/admin/categorie1/souscategorie14',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Catégorie 2',
    path: '/admin/Categorie2',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Sous Catégorie 21',
        path: '/admin/categorie2/souscategorie21',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Sous Catégorie 22',
        path: '/admin/categorie2/souscategorie22',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Sous Catégorie 23',
        path: '/admin/categorie2/souscategorie23',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Catégorie 3',
    path: '/admin/Categorie3',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Sous Catégorie 31',
        path: '/admin/categorie3/souscategorie31',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Sous Catégorie 32',
        path: '/admin/categorie3/souscategorie32',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Sous Catégorie 33',
        path: '/admin/categorie3/souscategorie33',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  }
];
