import React, { useEffect, useState } from "react";
import etap1 from "../../styles/assets/etap1.webp";
import etap2 from "../../styles/assets/etap2.webp";
import etap3 from "../../styles/assets/etap3.webp";
import { Link } from 'react-router-dom'


export default function TreeCategorie() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);

    return (
      <div
        style={{
          marginTop: "100px",
        }}
        className="tree-categorie"
      >
        <h3 className="sempacoTient">
          Sempaco tient à fournir tout ce dont vous avez besoin en mettant en
          place tous les moyens <br /> afin de vous faciliter l’accès à tous ses
          produits et ses services.
        </h3>

{/* ///////////////////////////////////////////////////////////////////////////////// */}
{screenWidth > 1200 && (

<div style={{display: "flex" , flexDirection: "column" , justifyContent: "center" , alignItems: "center"}}>
          <span style={{ display: "flex", justifyContent:"space-around", flexWrap: "wrap" , width: "80%", marginTop: "50px" }}>
          <span style={{width: "50%",flexDirection: "column" , display: "flex", alignItems: "center" , justifyContent: "center"}}>
            <img
              style={{ maxHeight: "3.23529rem" }}
              src={etap1}
              alt="React Logo"
            />
            <Link to="/categorie1" style={{ color: "Black" , textDecoration: "none" }}>
                <h1>Bases parfum</h1>
            </Link>
            </span>
            <span style={{width: "50%"}}>
            <p
              style={{
                width: "500px",
                fontSize: "25px",
                textAlign: "start",
                marginTop: "0.58824rem",
                color: "#5D5D69",
              }}
            >
              Sempaco vous sélectionne une gamme d’essences aussi originales en
              vous garantissant un meilleur rapport qualité /prix.
            </p>
          </span>
          </span>
          <span style={{display: "flex", justifyContent:"space-between" , flexWrap: "wrap" , width: "70%", marginTop: "200px" }}>
          <span style={{width: "50%"}}>
            <p
              style={{
                width: "500px",
                fontSize: "25px",
                textAlign: "start",
                marginTop: "0.58824rem",
                marginLeft: "10px",
                color: "#5D5D69",
              }}
            >
              Sempaco est reconnue grâce aux multiples choix d’articles
              d’emballage et d’accessoire en différentes matières( pvc, pet,
              verre, …).
            </p>
            </span>
          <span style={{width: "50%",flexDirection: "column" , display: "flex", alignItems: "center" , justifyContent: "center"}}>
            <img
              style={{ maxHeight: "3.23529rem" }}
              src={etap2}
              alt="React Logo"
            />
            <Link to="/categorie2" style={{ color: "Black" , textDecoration: "none" }}>
                  <h1>Emballage </h1>
            </Link>
            </span>
          </span>
          <span style={{display: "flex", justifyContent:"space-between" , flexWrap: "wrap" , width: "70%", marginTop: "250px" }}>
          <span style={{width: "45%",flexDirection: "column" , display: "flex", alignItems: "center" , justifyContent: "center"}}>
            <img
              style={{ maxHeight: "3.23529rem" }}
              src={etap3}
              alt="React Logo"
            />
            <Link style={{ color: "Black" , textDecoration: "none" }} to="/categorie3">
                <h1>My Variety</h1>
            </Link>
            </span>
            <span style={{width: "55%"}}>
            <p
              style={{
                width: "500px",
                fontSize: "25px",
                textAlign: "start",
                marginTop: "0.58824rem",
                color: "#5D5D69",
              }}
            >
              D’autres produits et articles font partie de ce qu’on vous propose.</p>
            </span>
          </span>
          </div>
)}
{/* ///////////////////////////////////////////////////////////////////////////////// */}
{screenWidth < 1200 && (
        <section
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <span style={{ maxWidth: "350px", marginTop: "50px" }}>
            <img
              style={{ maxHeight: "3.23529rem" }}
              src={etap1}
              alt="React Logo"
            />
            <Link to="/categorie1" style={{ color: "Black" , textDecoration: "none" }}>
                <h1>Bases parfum</h1>
            </Link>
            <p
              style={{
                textAlign: "start",
                marginTop: "0.58824rem",
                color: "#5D5D69",
              }}
            >
              Sempaco vous sélectionne une gamme d’essences aussi originales en
              vous garantissant un meilleur rapport qualité /prix.
            </p>
          </span>
          <span style={{ maxWidth: "350px", marginTop: "50px" }}>
            <img
              style={{ maxHeight: "3.23529rem" }}
              src={etap2}
              alt="React Logo"
            />
            <Link to="/categorie2" style={{ color: "Black" , textDecoration: "none" }}>
                  <h1>Emballage </h1>
            </Link>
            <p
              style={{
                textAlign: "start",
                marginTop: "0.58824rem",
                color: "#5D5D69",
              }}
            >
              Sempaco est reconnue grâce aux multiples choix d’articles
              d’emballage et d’accessoire en différentes matières( pvc, pet,
              verre, …).
            </p>
          </span>
          <span style={{ maxWidth: "350px", marginTop: "50px" }}>
            <img
              style={{ maxHeight: "3.23529rem" }}
              src={etap3}
              alt="React Logo"
            />
            <Link style={{ color: "Black" , textDecoration: "none" }} to="/categorie3">
                <h1>My Variety</h1>
            </Link>
            <p
              style={{
                textAlign: "start",
                marginTop: "0.58824rem",
                color: "#5D5D69",
              }}
            >
              D’autres produits et articles font partie de ce qu’on vous propose.
            </p>
          </span>
        </section>
)}
      </div>
    );
}
