import image1 from "../../styles/assets/car1.webp";
import image11 from "../../styles/assets/car11.webp";
import image2 from "../../styles/assets/car2.webp";
import image22 from "../../styles/assets/car22.webp";
import image3 from "../../styles/assets/car3.webp";
import image33 from "../../styles/assets/car33.webp";
import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    src: image1,
    src1: image11,
    altText: '',
    caption: ''
  },
  {
    src: image2,
    src1: image22,
    altText: '',
    caption: ''
  },
  {
    src: image3,
    src1: image33,
    altText: '',
    caption: ''
  }
];

class CarouselCom extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} className="imgCorsser"/>
          <img src={item.src1} className="imgResCorsser"/>
          <CarouselCaption  />
        </CarouselItem>
        
      );
    });

    return (
      <span className="carousel">
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators  items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl  direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
      </span>
      
    );
  }
}


export default CarouselCom;
