import React from 'react'
import img1 from "../../styles/assets/img1.png";
import img2 from "../../styles/assets/img2.png";
import logo from "../../styles/assets/logo.PNG";
import { Link } from 'react-router-dom'

export function SempacoLogo() {
    return (
      <section className='sempacoLogo'>
        <Link style={{textDecoration : "none"}} to="/">
          {/* <img 
           //className="imgstylelogo1" 
           style={{height : "30px" , width: "auto" ,marginRight: "5px"}}
           src={img1} alt="logo" 
           />
          <img 
           //className="imgstylelogo2"  
           style={{height : "20px" , width: "auto"}}
          src={img2} alt="logo" /> */}
          {/* <h3  style={{ color: "#535891" }}>SEMPACO</h3> */}
          <img src={logo} style={{width : "60px" , height : "auto"}}/>
         </Link>
      </section>
    );
  }