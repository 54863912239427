import React, { Component } from "react";

export default class CallSupport extends Component {
  render() {
    return (
      <div>
        <section style={{ maxWidth: "80%", margin: "auto" }}>
          <h1
            style={{
              marginTop: "100px",
              color: "#121118",
              fontWeight: "700",
              letterSpacing: "normal",
              fontSize: "2.41176rem",
              lineHeight: "2.94118rem",
              textAlign: "center",
            }}
          >
            Contactez-nous
          </h1>
          <p
            style={{
              color: "rgb(93, 93, 105)",
              marginTop: "0.58824rem",
              fontWeight: "400",
              letterSpacing: "normal",
              fontSize: "1.41176rem",
              lineHeight: "1.94118rem",
              textAlign: "center",
            }}
          >
            Par souci de proximité et d’écoute, Sempaco a le plaisir de répondre
            à toutes vos demandes et de vos questions.
          </p>
          <p
            style={{
              color: "rgb(93, 93, 105)",
              marginTop: "0.58824rem",
              fontWeight: "400",
              letterSpacing: "normal",
              fontSize: "1.41176rem",
              lineHeight: "1.94118rem",
              textAlign: "center",
            }}
          >
            Afin de vous faciliter toutes nos opérations de vente, on offre les
            services de transport sur toute la Tunisie.
            Votre service et notre satisfaction 
          </p>
        </section>
      </div>
    );
  }
}
